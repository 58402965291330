import { Component, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { Globals } from 'src/classes/global';

@Component({
  selector: 'app-home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrl: './home-carousel.component.scss'
})
export class HomeCarouselComponent {
  @ViewChild('slickModal') carousel!: SlickCarouselComponent;

  constructor(public global: Globals,
    protected deviceService: DeviceDetectorService) { }  
    
  slidesIT = [
    {img: "https://static.rms.it/evok/banner_home/banner_home_8_IT.jpg", href: "https://evok-performance.com/it/datasheet/P100290025/KIT_FRIZIONE_EVOK_SOFT_IRON_CLUTCH_VESPA_SMALL_FRAME", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_7_IT.jpg", href: "https://www.evok-performance.com/it/search/fanale%20ape%20car", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_1_IT.jpg", href: "https://www.evok-performance.com/it/search/IRON%20CLUTCH", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_2_IT.jpg", href: "https://www.evok-performance.com/it/search/iron%20brake", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_3_IT.jpg", href: "", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_5_IT.jpg", href: "https://www.evok-performance.com/it/search-products/C190-471/Telaio-Accessori_Comandi", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_6_IT.jpg", href: "https://www.evok-performance.com/it/search-products/C10000-722/Alimentazione-Getti_Carburatore", title: "Evok Banner Home"}
  ];

  slidesEN = [
    {img: "https://static.rms.it/evok/banner_home/banner_home_8_EN.jpg", href: "https://evok-performance.com/en/datasheet/P100290025/CLUTCH_KIT_EVOK_SOFT_IRON_CLUTCH_VESPA_SMALL_FRAME", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_7_EN.jpg", href: "https://www.evok-performance.com/en/search-products/C183-558/Electric_system-Headlights", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_1_EN.jpg", href: "https://www.evok-performance.com/en/search/IRON%20CLUTCH", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_2_EN.jpg", href: "https://www.evok-performance.com/en/search/IRON%20BRAKE", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_3_EN.jpg", href: "", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_5_EN.jpg", href: "https://www.evok-performance.com/en/search-products/C190-471/Chassis-Controls_accessories", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_6_EN.jpg", href: "https://www.evok-performance.com/en/search-products/C10000-722/Fuel_system-Carburetor_jets", title: "Evok Banner Home"}
  ];

  slidesES = [
    {img: "https://static.rms.it/evok/banner_home/banner_home_8_ES.jpg", href: "https://evok-performance.com/es/datasheet/P100290025/KIT_EMBRAGUE_EVOK_SOFT_IRON_CLUTCH_VESPA_SMALL_FRAME", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_7_ES.jpg", href: "https://www.evok-performance.com/es/search-products/C183-558/Electricidad-Faros", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_1_ES.jpg", href: "https://www.evok-performance.com/es/search/EVOK%20IRON%20CLUTCH", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_2_ES.jpg", href: "https://www.evok-performance.com/es/search/IRON%20BRAKE", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_3_ES.jpg", href: "", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_5_ES.jpg", href: "https://www.evok-performance.com/es/search-products/C190-471/Chasis-Accesorios_mandos", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_6_ES.jpg", href: "https://www.evok-performance.com/es/search-products/C10000-722/Sistema_de_combustible-Set_chicles", title: "Evok Banner Home"}
  ];

  slidesFR = [
    {img: "https://static.rms.it/evok/banner_home/banner_home_8_FR.jpg", href: "https://evok-performance.com/fr/datasheet/P100290025/Kit_embrayage_Evok_soft_iron_clutch_Vespa_small_frame", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_7_FR.jpg", href: "https://www.evok-performance.com/fr/search-products/C183-558/Syst_me_lectrique-Phares", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_1_FR.jpg", href: "https://www.evok-performance.com/fr/search/EVOK%20IRON%20CLUTCH", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_2_FR.jpg", href: "https://www.evok-performance.com/fr/search/IRON%20BRAKE", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_3_FR.jpg", href: "", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_5_FR.jpg", href: "https://www.evok-performance.com/fr/search-products/C190-471/Cadre-Accessoires_controles", title: "Evok Banner Home"},
    {img: "https://static.rms.it/evok/banner_home/banner_home_6_FR.jpg", href: "https://www.evok-performance.com/fr/search-products/C10000-722/Syst_me_d_alimentation-Jeu_gicleur", title: "Evok Banner Home"}
  ];

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1, 
    "infinite": true,
    "dots": false,
    "arrows": this.deviceService.isMobile() ? false: true,
    "autoplay":true,
  };
    
  next() {
    this.carousel.slickNext();
  }

  prev() {
    this.carousel.slickPrev();
  }
}